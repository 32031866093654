import React, { Component } from 'react';
import NavBarController from "../navBar/NavBarController";


class Header extends Component {

    render() {
        return (
            <div className="showcase" style={{ width: "100%", backgroundImage: 'url('+this.props.url+')', backgroundPositionX: "center", backgroundSize: "cover", backgroundRepeat: "no-repeat" }}>
                <NavBarController/>
                <h1 className="text-light text-center display-2" style={{marginTop: '15%'}} >{this.props.title} </h1>
                {this.props.description ? <h3 className="text-light text-center">{this.props.description}</h3> : null }
            </div>
        )
    }
}

export default Header;