import React from "react";

import { BrowserRouter as Router } from 'react-router-dom';
import {createRoot} from "react-dom/client";
import Header from "../../components/header/Header";

const rootHome = document.getElementById('header');
const urlImage = rootHome.dataset.url;
const title = rootHome.dataset.title;
const description = rootHome.dataset.description;

console.log(urlImage);

const root = createRoot(rootHome);
root.render(<Router><Header url={urlImage} title={title} description={description}/></Router>);
